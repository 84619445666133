import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listAction as categoryListAction } from "../../network/store/action/SectionResponseAction";
import { listAction as brandListAction } from "../../network/store/action/BrandResponseAction";
import { listAction as taxsListAction } from "../../network/store/action/TaxResponseAction";
import { listAction as hsnListAction } from "../../network/store/action/HsnResponseAction";
import { listAction as colorListAction } from "../../network/store/action/ColorsResponseAction";
import { listAction as materialListAction } from "../../network/store/action/MaterialResponseAction";
import { listAction as uomListAction } from "../../network/store/action/UomResponseAction";

import "./products.css";
import { useNavigate } from "react-router-dom";
import { updateMessage } from "../../redux/slice/toastSlice";
import AppToast from "../../components/appToast";
import { createUpdateAction } from "../../network/store/action/ProductResponseAction";

function ProductAdd() {
  // to get api data

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sectionList, setSectionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [brandnList, setBrandList] = useState([]);
  const [taxList, setTaxsList] = useState([]);
  const [hsnList, setHsnList] = useState([]);
  const [colorList, setColorsList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [slab, setSlab] = useState({});
  const [selectedColor, setSelectedColor] = useState({});
  const [uomList, setUomList] = useState([]);

  const [productAddForm, setproductAddForm] = useState({
    sectionId: "",
    categoryId: "",
    brandId: "",
    taxId: "",
    colorId: "",
    materialId: "",
    hsnId: "",
    name: "",
    description: "",
    uomId: "",
    quantity: "",
    price: "",
    offer: "",
    maxQantity: "",
    sku: "",
    status: "YES",
  });

  useEffect(() => {
    getSections();
    getBrandList();
    gettaxList();
    getHsnList();
    getColorsList();
    getMaterialList();
    getUomList();
  }, []);

  const getUomList = async () => {
    await dispatch(uomListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setUomList(response.result);
      }
    });
  };

  const getBrandList = async () => {
    await dispatch(brandListAction({}, dispatch)).then((response) => {
      // console.log("sectionList", response.result);
      if (response.result) {
        setBrandList(response.result);
      }
    });
  };

  const gettaxList = async () => {
    await dispatch(taxsListAction({}, dispatch)).then((response) => {
      // console.log("setTaxsList", response.result);
      if (response.result) {
        setTaxsList(response.result);
      }
    });
  };

  const getHsnList = async () => {
    await dispatch(hsnListAction({}, dispatch)).then((response) => {
      //   console.log("sectionList", response.result);
      if (response.result) {
        setHsnList(response.result);
      }
    });
  };

  const getColorsList = async () => {
    await dispatch(colorListAction({}, dispatch)).then((response) => {
      //   console.log("sectionList", response.result);
      if (response.result) {
        setColorsList(response.result);
      }
    });
  };

  const getMaterialList = async () => {
    await dispatch(materialListAction({}, dispatch)).then((response) => {
      //   console.log("sectionList", response.result);
      if (response.result) {
        setMaterialList(response.result);
      }
    });
  };

  const getSections = async () => {
    await dispatch(categoryListAction({}, dispatch)).then((response) => {
      //   console.log("sectionList", response.result);
      if (response.result) {
        setSectionList(response.result);
      }
    });
  };

  const handleChanges = (e) => {
    //  console.log("SDebug > handleChanges", e);
    if (e.target.name === "sectionId") {
      const sectionIdex = sectionList.findIndex(
        (obj) => obj.id === e.target.value
      );
      setCategoryList(sectionList[sectionIdex].categories);
    }
    if (e.target.name === "taxId") {
      const selectedSlab = taxList.find((slab) => slab.id === e.target.value);

      setSlab(selectedSlab);
    }

    if (e.target.name === "colorId") {
      const selectedColor = colorList.find((obj) => obj.id === e.target.value);
      setSelectedColor(selectedColor);
    }

    setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value });
  };

  const onSubmtClick = async () => {
    if (checkValidation()) {
      let itemObj = {
        name: productAddForm.name,
        description: productAddForm.description,
        brand: productAddForm.brandId,
        materialType: productAddForm.materialId,
        sectionId: productAddForm.sectionId,
        categoryId: productAddForm.categoryId,
        status: "YES",
        price: productAddForm.price,
        qty: productAddForm.quantity,
        measure: productAddForm.uomId,
        offer: productAddForm.offer,
        c_gst: slab.cgst,
        s_gst: slab.sgst,
        slabId: slab.id,
        colorCode: selectedColor.color_code,
        colorName: selectedColor.name,
        sku: productAddForm.sku,
        hsn: productAddForm.hsnId,
      };

      await dispatch(createUpdateAction(itemObj, dispatch)).then((response) => {
        if (response.result) {
          dispatch(
            updateMessage({
              display: true,
              message: "Product Created.",
            })
          );
          navigate(-1);
        }
      });
    } else {
      dispatch(
        updateMessage({
          display: true,
          message: "Form Error.",
        })
      );
    }
  };

  const checkValidation = () => {
    var isFormValid = true;
    if (
      !productAddForm.name ||
      !productAddForm.description ||
      !productAddForm.categoryId ||
      !productAddForm.sectionId ||
      !productAddForm.materialId ||
      !productAddForm.brandId ||
      !productAddForm.taxId ||
      !productAddForm.colorId ||
      !productAddForm.hsnId ||
      !productAddForm.uomId ||
      !productAddForm.quantity ||
      !productAddForm.price ||
      !productAddForm.offer ||
      !productAddForm.maxQantity
    ) {
      isFormValid = false;
    }
    return isFormValid;
  };

  return (
    <>
      <AppToast />
      <main className="container-fluid dashboard">
        <div className="row justify-content-around tble">
          <div style={{ marginLeft: "50px" }}>
            <h3 className="dashboard-title">Create Product</h3>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-3">
            <div className="form-group">
              <label>Section</label>
              <select
                name="sectionId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.sectionId}
              >
                <option value={""}>{"--Select Section--"}</option>
                {sectionList &&
                  sectionList.length > 0 &&
                  sectionList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Category</label>
              <select
                name="categoryId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.categoryId}
              >
                <option value={""}>{"--Select Category--"}</option>
                {categoryList &&
                  categoryList.length > 0 &&
                  categoryList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Brand</label>
              <select
                name="brandId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.brandId}
              >
                <option value={""}>{"--Select Brand--"}</option>
                {brandnList &&
                  brandnList.length > 0 &&
                  brandnList.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Tax Slab</label>
              <select
                name="taxId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.taxId}
              >
                <option value={""}>{"--Select Tax Slab--"}</option>
                {taxList &&
                  taxList.length > 0 &&
                  taxList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              <div className="col-auto">
                <label>SGST: {slab.sgst} %</label>&nbsp;&nbsp;&nbsp;&nbsp;
                <label>CGST: {slab.cgst} %</label>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>HSN Code</label>
              <select
                name="hsnId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.hsnId}
              >
                <option value={""}>{"--Select HSN--"}</option>
                {hsnList &&
                  hsnList.length > 0 &&
                  hsnList.map((item, index) => {
                    return (
                      <option key={index} value={item.code}>
                        {item.code}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Color</label>
              <select
                name="colorId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.colorId}
              >
                <option value={""}>{"--Select Color--"}</option>
                {colorList &&
                  colorList.length > 0 &&
                  colorList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name} ({item.color_code} )
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Item / Material Type</label>
              <select
                name="materialId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.materialId}
              >
                <option value={""}>{"--Select Type--"}</option>
                {materialList &&
                  materialList.length > 0 &&
                  materialList.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>SKU</label>
              <input
                type="text"
                name="sku"
                className="form-control"
                value={productAddForm.sku}
                onChange={(e) => handleChanges(e)}
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Name</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={productAddForm.name}
                onChange={(e) => handleChanges(e)}
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Description</label>
              <textarea
                type="text"
                name="description"
                className="form-control"
                value={productAddForm.description}
                onChange={(e) => handleChanges(e)}
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Quantity</label>
              <input
                type="text"
                name="quantity"
                className="form-control"
                value={productAddForm.quantity}
                onChange={(e) => handleChanges(e)}
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>UOM (Measure)</label>
              <select
                name="uomId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={productAddForm.uomId}
              >
                <option value={""}>{"--Select UOM--"}</option>
                {uomList &&
                  uomList.length > 0 &&
                  uomList.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Price</label>
              <input
                type="text"
                name="price"
                className="form-control"
                value={productAddForm.price}
                onChange={(e) => handleChanges(e)}
              />
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label>Offer (%)</label>
              <input
                type="text"
                name="offer"
                className="form-control"
                value={productAddForm.offer}
                onChange={(e) => handleChanges(e)}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>User purchase Max Quantity</label>
              <input
                type="text"
                name="maxQantity"
                className="form-control"
                value={productAddForm.maxQantity}
                onChange={(e) => handleChanges(e)}
              />
            </div>
          </div>

          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              style={{ display: "flex", justifyContent: "center" }}
              type="button"
              className="btn btn-purple-bg"
              onClick={() => onSubmtClick()}
            >
              Submit
            </button>
            <button className="btn btn-white-bg" onClick={() => navigate(-1)}>
              Cancel
            </button>
          </div>
        </div>
      </main>
    </>
  );
}
export default ProductAdd;
