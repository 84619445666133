import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listAction } from "../../network/store/action/SectionResponseAction";
import noImage from "../../assets/no_image.jpg";
import { listAction as productListAction } from "../../network/store/action/ProductResponseAction";
import { FaEdit, FaPlus } from "react-icons/fa";

function CreateOrder() {
  const dispatch = useDispatch();
  const [resultList, setResultList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [productAddForm, setproductAddForm] = useState({
    sectionId: "",
    categoryId: "",
    storeId: 0,
  });

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    getProducts(
      productAddForm.sectionId,
      productAddForm.categoryId,
      productAddForm.storeId
    );
  }, [productAddForm]);

  const loadData = async () => {
    await dispatch(listAction(dispatch)).then((reponse) => {
      if (reponse.result) {
        setproductAddForm({
          ...productAddForm,
          categoryId: reponse.result[0].id,
          sectionId: "",
        });
        setResultList(reponse.result);
      }
    });
  };

  const getProducts = async (sectionId, categoryId, storeId) => {
    await dispatch(
      productListAction(
        {
          limit: 100,
          offset: 0,
          sectionId: sectionId,
          categoryId: categoryId,
          storeId: storeId,
        },
        dispatch
      )
    ).then((reponse) => {
      if (reponse.result) {
        setProductList(reponse.result);
      } else {
        setProductList([]);
      }
    });
  };
  const onCategorySelect = (item) => {
    const sectionIdex = resultList.findIndex((obj) => obj.id === item.id);
    setCategoryList(resultList[sectionIdex].categories);
    setproductAddForm({ ...productAddForm, sectionId: item.id });
  };

  const onSubCategorySelect = (item) => {
    console.log("SelectedSub Category", item);
    setproductAddForm({ ...productAddForm, categoryId: item.id });
  };

  const handleChanges = (e) => {
    console.log("SDebug > handleChanges", e);
    if (e.target.name === "sectionId") {
      const sectionIdex = resultList.findIndex(
        (obj) => obj.id === e.target.value
      );
      setCategoryList(resultList[sectionIdex].categories);
      setproductAddForm({
        ...productAddForm,
        categoryId: "",
        sectionId: e.target.value,
      });
    } else {
      setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value });
    }

    //  getProducts(pageLimit,pageOffset, e.target.name === 'sectionId'?e.target.value : '', e.target.name === 'categoryId'?e.target.value : '');
    //  setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value })
  };

  return (
    <main className="container-fluid dashboard nopadding">
      <div className="row m-3">
        <div className="col-md-12">
          <h3 className="dashboard-title">Create Order</h3>
        </div>
      </div>
      <div className="row m-3">
        <div className="col-md-3">
          <div className="form-group">
            <label>Section</label>
            <select
              name="sectionId"
              className="form-select"
              onChange={(e) => handleChanges(e)}
              value={productAddForm.sectionId}
            >
              {/* <option value={""}>{"--Select Section--"}</option> */}
              {resultList &&
                resultList.length > 0 &&
                resultList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="col-md-3">
          <div className="form-group">
            <label>Category</label>
            <select
              name="categoryId"
              className="form-select"
              onChange={(e) => handleChanges(e)}
              value={productAddForm.categoryId}
            >
              <option value={""}>{"--Select Category--"}</option>
              {categoryList &&
                categoryList.length > 0 &&
                categoryList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </div>

      <div className="row m-3">
        {/* <div
          className="col-md-2 card-1 overflow-auto"
          style={{ marginRight: "10px" }}
        >
          <table className="table table-hover align-middle">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Category</th>
              </tr>
            </thead>
            {resultList && resultList.length > 0 ? (
              <tbody>
                {resultList.map((item, i) => {
                  return (
                    <tr onClick={() => onCategorySelect(item)}>
                      <td className="align-middle">
                        <p>{item.name}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <p>No Data found</p>
            )}
          </table>
        </div> */}

        {/* <div
          className="col-md-2 card-1 overflow-auto"
          style={{ marginRight: "10px" }}
        >
          <table className="table table-hover align-middle">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Sub Category</th>
              </tr>
            </thead>
            {categoryList && categoryList.length > 0 ? (
              <tbody>
                {categoryList.map((item, i) => {
                  return (
                    <tr onClick={() => onSubCategorySelect(item)}>
                      <td className="align-middle">
                        <p>{item.name}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <p>No Data found</p>
            )}
          </table>
        </div> */}
        <div
          className="col-md-4 card-1 overflow-auto"
          style={{ padding: "10px" }}
        >
          <h5>Products</h5>

          {productList && productList.length > 0 ? (
            <>
              {productList.map((product, i) => {
                return (
                  <div className="container card-1 mb-2">
                    <div className="grid-container">
                      <p>{product.item.name}</p>
                      {product.prices &&
                        product.prices.length > 0 &&
                        product.prices.map((price, i) => {
                          return (
                            <>
                              <div className="price-box position-rproductative m-1">
                                <span
                                  className="float-edit-btn"
                                  onClick={() => {}}
                                >
                                  <FaPlus />
                                </span>
                                <span>
                                  {price.qty} {price.measure}{" "}
                                </span>
                                <br />
                                <span>
                                  {price.offer_price !== "" ? (
                                    <>
                                      <b>{"₹" + price.offer_price}</b>{" "}
                                      <span
                                        style={{
                                          textDecoration: "line-through",
                                          color: "grey",
                                        }}
                                      >
                                        {"₹" + price.price}
                                      </span>
                                    </>
                                  ) : (
                                    <b>{"₹" + price.price}</b>
                                  )}{" "}
                                </span>
                              </div>
                            </>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <p>No Data found</p>
          )}

          {/* <table className="table table-hover align-middle">
            {productList && productList.length > 0 ? (
              <tbody>
                {productList.map((product, i) => {
                  return (
                    <tr>
                      <td className="align-middle">
                        <p>{product.item.name}</p>
                      </td>
                      <td>
                        <div className="col-md-8">
                          <select
                            name="sectionId"
                            className="form-select"
                            // onChange={(e) => handleChanges(e)}
                            // value={productAddForm.sectionId}
                          >
                            <option value={""}>{"Quantity"}</option>
                            {product.prices &&
                              product.prices.length > 0 &&
                              product.prices.map((item, index) => {
                                return (
                                  <option key={index} value={item.id}>
                                    {item.qty} {item.measure}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </td>
                      <td> + ADD</td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <p>No Data found</p>
            )}
          </table> */}
        </div>
      </div>
    </main>
  );
}

export default CreateOrder;
