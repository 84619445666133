import { useCallback, useState } from "react";
// import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import getCroppedImg from "./crop";

const EasyCrop = ({ image, onCrop, onCropClose }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );
      console.log("donee", { croppedImage });
      if (onCrop) onCrop(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, image]);

  const onClose = useCallback(() => {
    if (onCropClose) onCropClose();
  }, []);

  return (
    <div>
      <div
        className="row p-5"
        style={{
          display: image === null || croppedImage !== null ? "none" : "block",
          //   width: "100%",
          //   display: "block",
          //   height: "100%",
          //   background: "#fff",
          //   position: "absolute"
        }}
      >
        <div className="crop-container">
          <Cropper
            image={image}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            zoomSpeed={4}
            maxZoom={3}
            zoomWithScroll={true}
            showGrid={true}
            aspect={2 / 3}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
          />
        </div>
        {/* <div className="controls">
          <label>
            Rotate
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              aria-labelledby="rotate"
              onChange={(e, rotation) => setRotation(rotation)}
              className="range"
            />
          </label>
          <label>
            Zoom
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="zoom"
              onChange={(e, zoom) => setZoom(zoom)}
              className="range"
            />
          </label>
        </div> */}
      </div>

      {/* <button
        style={{
          display: image === null || croppedImage !== null ? "none" : "block",
        }}
        onClick={showCroppedImage}
      >
        Crop
      </button> */}
      {/* <div className="cropped-image-container">
        {croppedImage && (
          <img className="cropped-image" src={croppedImage} alt="cropped" />
        )}
        {croppedImage && <button onClick={onClose}>close</button>}
      </div> */}

      <div
        style={{
          position: "absolute",
          bottom: "0",
          padding: "20px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          background: "#fff",
        }}
      >
        <button
          style={{ display: "flex", justifyContent: "center" }}
          type="button"
          className="btn btn-primary"
          onClick={showCroppedImage}
        >
          Crop
        </button>
        <button
          className="btn btn-white-bg"
          onClick={() => {
            onClose();
            //   setShowProductDeleteModel(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default EasyCrop;
