import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import {getDatabase} from "firebase/database"

const firebaseConfig = {
  apiKey: "AIzaSyBWuajZ_Pe0reuLfYsC4st0SERFe-hb-YY",
  authDomain: "gromacity-8000a.firebaseapp.com",
  projectId: "gromacity-8000a",
  storageBucket: "gromacity-8000a.appspot.com",
  messagingSenderId: "140997829365",
  appId: "1:140997829365:web:d6def5c193a0f35ce0dbbc",
  measurementId: "G-6BY6799FS3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const database = getDatabase(app);
