import { PuffLoader } from "react-spinners";
import { loadingStatus } from "../redux/slice/loaderSlice";
import { useSelector } from "react-redux";

function AppLodingBar(props) {
  const isLoading = useSelector(loadingStatus);
  return (
    <>
      <div
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          zIndex: "999",
          overflow: "hidden",
        }}
      >
        <div className="loaderParentDiv">
          <div className="loaderDivCenter">
            <PuffLoader color="#7d04a9" loading={isLoading} size={80} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AppLodingBar;
