import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listAction } from "../../network/store/action/OrderResponseAction";
// import { Table } from "react-bootstrap";
import GMCTPagination from "../../components/pagination";
import { FaRegEdit, FaTrash } from "react-icons/fa";

function Orders() {
  // to get api data

  const dispatch = useDispatch();

  // list of items
  const [resultList, setResultList] = useState([]);
  const [showRequestForm, setShowRequestForm] = useState(false);
  //Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);
  const [pageOffset, setPageOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    await dispatch(listAction(dispatch)).then((reponse) => {
      if (reponse.result) {
        setResultList(reponse.result);
      } else {
        resetFilter();
      }
    });
  };

  const resetFilter = () => {
    setPageOffset(0);
    setTotalCount(0);
    setResultList([]);
    setPageCount(0);
    setActivePage(1);
  };
  const handlePageChange = (selectedPage) => {
    setActivePage(selectedPage);
    setPageOffset((selectedPage - 1) * pageLimit);
  };
  const handleLimitChange = (e) => {
    setPageLimit(e.target.value);
    setPageOffset(0);
    setActivePage(1);
  };

  const getOrderStatusMessage = (orderstatus) => {
    let message = "";
    let indicationColor = "#ffff";
    if (orderstatus === "0") {
      message = "Pending";
    } else if (orderstatus === "1") {
      message = "Accepted";
      indicationColor = "#fc9003";
    } else if (orderstatus === "2") {
      message = "Assigned";
      indicationColor = "#fc9003";
    } else if (orderstatus === "3") {
      message = "Out for Delivery";
      indicationColor = "#06a9d1";
    } else if (orderstatus === "5") {
      message = "Delivered";
      indicationColor = "#02a612";
    } else if (orderstatus === "6") {
      message = "Cancelled";
      indicationColor = "#ba1000";
    }
    return (
      <span
        style={{
          padding: "5px 10px",
          backgroundColor: indicationColor,
          color: "#ffff",
          borderRadius: "20px",
          fontSize: ".7rem",
          maxWidth: "100px",
        }}
      >
        {message}
      </span>
    );
  };

  return (
    <>
      {showRequestForm && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <div className="d-flex align-item-center justify-content-center">
            <div className="col-md-8">
              {/* <BannerForm onClose={() => {setShowRequestForm(false)}} sectionList={[]}/> */}
            </div>
          </div>
        </div>
      )}

      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="col-md-12">
            <h3 className="dashboard-title">Orders</h3>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-9 card-1 overflow-auto">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Order ID</th>
                  <th scope="col">Slot</th>
                  <th scope="col">Store</th>
                  <th scope="col">Customer</th>
                  <th scope="col">Status</th>
                  {/* <th scope="col">Payment Type</th> */}
                  <th scope="col">Amount</th>
                  {/* <th scope="col">Action</th> */}
                </tr>
              </thead>
              {resultList && resultList.length > 0 ? (
                <tbody>
                  {resultList.map((item, i) => {
                    return (
                      <tr key={i}>
                        {/* <th scope="row">{od.id}</th> */}
                        <td className="align-middle">
                          <p>{"GMCT" + item.info.id}</p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {item.info.slot_date} <br />
                            {item.info.slot_time}
                          </p>
                        </td>
                        <td className="align-middle">
                          {/* <p>{item.info.address}</p> */}
                          <p>Kavali</p>
                        </td>
                        <td className="align-middle">
                          <p>
                            {"user"}
                            <br />
                            <span className="mini-text">{"XXXXX6757"}</span>
                          </p>
                        </td>
                        <td className="align-middle">
                          <p>{getOrderStatusMessage(item.info.order_status)}</p>
                        </td>
                        {/* <td className="align-middle">
                        <p>{item.info.payment_type}</p>
                      </td> */}
                        <td className="align-middle">
                          <p>{item.info.total_roundoff}</p>
                        </td>
                        {/* <td className="align-middle">
                       <p><span className="edit-icon-btn" onClick={() => {setShowRequestForm(true)}}><FaRegEdit /></span> <span  className="delete-icon-btn"><FaTrash /></span></p>
                      </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <p>No Data found</p>
              )}
            </table>
            <GMCTPagination
              pageCount={pageCount}
              activePage={activePage}
              totalCount={totalCount}
              onPageChange={(page) => handlePageChange(page)}
              onLimitChange={(e) => handleLimitChange(e)}
            />
          </div>

          <div className="col-md-3 card-1 overflow-auto">
            <div className="col-md-12">
              <div className="form-group">
                <label>Order Id</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={""}
                  placeholder="Enter Product name"
                />
              </div>

              <div className="form-group">
                <label>Customer Number</label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={""}
                  placeholder="Enter Product name"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label>Store</label>
                {/* <select name="sectionId" className="form-select" onChange={(e) => handleChanges(e)} value={productAddForm.sectionId}>
                    <option value={''}>{'--Select Section--'}</option>
                    {sectionList && sectionList.length > 0 && sectionList.map((item, index) => {
                      return(
                        <option key={index} value={item.info.id}>{item.info.name}</option>
                      )
                    })}
                  </select> */}
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label>Status</label>
                {/* <select name="categoryId" className="form-select" onChange={(e) => handleChanges(e)} value={productAddForm.categoryId}>
                    <option value={''}>{'--Select Category--'}</option>
                    {categoryList && categoryList.length > 0 && categoryList.map((item, index) => {
                      return(
                        <option key={index} value={item.info.id}>{item.info.name}</option>
                      )
                    })}
                  </select> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Orders;
