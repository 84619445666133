import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import { listAction } from "../../network/store/action/SubCategoryResponseAction";
import { listAction as categoryListAction } from "../../network/store/action/SectionResponseAction";
import SubCategoryItem from "./sub-category-item";
import {
  formUpdateData,
  formUpdateStatus,
  imageUploadStatus,
  updateFormInfo,
} from "../../redux/slice/formUpdateSlice";
import AddUpdateForm from "./add-update-form";
import { updateMessage } from "../../redux/slice/toastSlice";
import AppToast from "../../components/appToast";
import AppLodingBar from "../../components/loader";
import { changeStatus } from "../../redux/slice/loaderSlice";
import SubCatImageUpload from "./sub-cat-image-upload";

function SubCategory() {
  const dispatch = useDispatch();

  // list of items
  const [resultList, setResultList] = useState([]);
  const showRequestForm = useSelector(formUpdateStatus);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  const [filterList, setFilterList] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  const [sectionList, setSectionList] = useState([]);
  const [filterOptions, setfilterOptions] = useState({
    sectionId: "",
  });

  const imageUpload = useSelector(imageUploadStatus);
  const formData = useSelector(formUpdateData);

  useEffect(() => {
    getSections();
    loadData();
  }, []);

  useEffect(() => {
    getSections();
    loadData();
  }, [filterOptions]);

  const getSections = async () => {
    await dispatch(categoryListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setSectionList(response.result);
      }
    });
  };

  const handleChanges = (e) => {
    console.log("SDebug > handleChanges", e);
    if (e.target.name === "sectionId") {
      sectionList.findIndex((obj) => obj.id === e.target.value);
      setfilterOptions({
        ...filterOptions,
        sectionId: e.target.value,
      });
    } else {
      setfilterOptions({ ...filterOptions, [e.target.name]: e.target.value });
    }
  };
  const loadData = async () => {
    await dispatch(
      listAction(
        {
          secId: filterOptions.sectionId,
        },
        dispatch
      )
    ).then((reponse) => {
      setFilterList([]);

      if (reponse.result) {
        setResultList(reponse.result);
      } else {
        setResultList([]);
      }
    });
  };

  function handleSearchClick(e) {
    const searchTerm = e.target.value;
    setSearchVal(searchTerm);
    console.log("search Value", e.target.value);
    if (searchTerm === "") {
      setResultList(resultList);
      setFilterList([]);
    } else {
      const filterBySearch = resultList.filter((item) => {
        if (item.name.toLowerCase().includes(searchTerm.toLowerCase())) {
          return item;
        }
      });
      setFilterList(filterBySearch);
    }
  }

  const onSubmit = () => {
    dispatch(changeStatus(false));
    dispatch(updateFormInfo({ displayForm: false, formData: {} }));
    dispatch(
      updateMessage({
        display: true,
        message: "Record updated",
      })
    );
    setResultList([]);
    setSearchVal("");
    setFilterList([]);

    loadData();
  };

  return (
    <>
      {showRequestForm && (
        <AddUpdateForm
          categories={sectionList}
          onSubmit={() => {
            onSubmit();
          }}
        />
      )}

      {imageUpload && (
        <SubCatImageUpload
          item={formData}
          onCancelClick={(reloadStatus) => {
            // setIsProductUpdate(reloadStatus);
          }}
        />
      )}
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Sub Categories</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => {
                  dispatch(
                    updateFormInfo({
                      displayForm: true,
                      formData: {},
                    })
                  );
                }}
              >
                + Add SubCategory
              </button>
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-3">
            <div className="form-group">
              <label>Search</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={searchVal}
                placeholder="Enter Sub category name"
                onChange={(e) => handleSearchClick(e)}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label>Category</label>
              <select
                name="sectionId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={filterOptions.sectionId}
              >
                <option value={""}>{"--Select Category--"}</option>
                {sectionList &&
                  sectionList.length > 0 &&
                  sectionList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-md-12 card-1">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Id</th>
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Category</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {searchVal === "" && resultList && resultList.length > 0 ? (
                <tbody>
                  {resultList.map((item, i) => {
                    return <SubCategoryItem item={item} key={item.id} />;
                  })}
                </tbody>
              ) : filterList.length > 0 ? (
                <tbody>
                  {filterList.map((item, i) => {
                    return <SubCategoryItem item={item} key={item.id} />;
                  })}
                </tbody>
              ) : (
                <p>No Data found</p>
              )}
            </table>
          </div>
        </div>
      </main>
    </>
  );
}

export default SubCategory;
