import { useEffect, useState } from "react";
import { FaImage, FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { updateFormInfo } from "../../redux/slice/formUpdateSlice";
import { createUpdateAction } from "../../network/store/action/BrandResponseAction";
import { updateMessage } from "../../redux/slice/toastSlice";

const BrandItem = (props) => {
  const [item, setItem] = useState(props.item);
  const [itemStatus, setitemStatus] = useState(item.status);
  const dispatch = useDispatch();

  const editItem = () => {
    console.log(item);
    dispatch(updateFormInfo({ displayForm: true, formData: { item } }));
  };

  const onStatusChange = async () => {
    let status = "YES";
    if (item.status == "NO") {
      status = "YES";
    } else {
      status = "NO";
    }

    let requestBody = {
      status: status,
      id: item.id,
    };

    await dispatch(createUpdateAction(requestBody)).then((response) => {
      if (response.status == true) {
        setItem(item, (item.status = status));
        setitemStatus(status);
        console.log("AFTER Change", item.status);
      } else {
        dispatch(
          updateMessage({
            display: true,
            message: "Error While updating info",
          })
        );
      }
    });
  };

  return (
    <tr key={item.id}>
      {/* <th scope="row">{od.id}</th> */}
      <td className="align-middle">
        <p>{item.id}</p>
      </td>
      {/* <td className="align-middle"><img src={item.image_path} alt={item.name} width="100px" /></td> */}
      <td className="align-middle">
        <p>{item.name}</p>
      </td>
      {/* <td className="align-middle">
    <p>{item.type}</p>
  </td> */}
      <td className="align-middle">
        <div class="form-check form-switch col-lg-12 d-flex justify-content-center">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            onChange={onStatusChange}
            checked={itemStatus === "YES" ? true : false}
          />
        </div>
      </td>
      <td className="align-middle">
        <p className="col-lg-12 d-flex justify-content-center">
          <span
            className="edit-icon-btn"
            onClick={() => {
              editItem();
            }}
          >
            <FaRegEdit />
          </span>{" "}
          <span className="delete-icon-btn">
            <FaTrash />
          </span>
        </p>
      </td>
    </tr>
  );
};

export default BrandItem;
