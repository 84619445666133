import { ref, set, get, child, update, remove } from "firebase/database";
import {database} from "../firebase";
import { Children } from "react";


const db = database;

class FirebaseDataService {
  getAll() {
    return get(child(ref(db), `products/`))
  }

  create(id, itemData) {
   return set(ref(db, `products/`+id), itemData)
    // return db.push(tutorial);
  }

  update(key, value) {
    return db.child(key).update(value);
  }

  delete(id) {
    return remove(ref(db, `products/`+id))
  }

  deleteAll() {
    return db.remove();
  }
}

export default new FirebaseDataService();
