import { Toast } from "react-bootstrap";
import { toastStatus, updateMessage } from "../redux/slice/toastSlice";
import { useDispatch, useSelector } from "react-redux";

function AppToast() {
  const toastMsgInfo = useSelector(toastStatus);
  const dispatch = useDispatch();

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 70,
          right: 10,
        }}
      >
        <Toast
          onClose={() =>
            dispatch(updateMessage({ display: false, message: "" }))
          }
          show={toastMsgInfo.display}
          delay={3000}
          autohide
        >
          <Toast.Header className="table" closeButton={false}>
            <strong className="mr-auto">Alert !!</strong>
          </Toast.Header>
          <Toast.Body className="table">{toastMsgInfo.message}</Toast.Body>
        </Toast>
      </div>
    </>
  );
}

export default AppToast;
