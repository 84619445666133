import React, { useState } from "react";
import { Form, Button, ProgressBar } from "react-bootstrap";
import Stepper from "react-stepper-horizontal";
import { useNavigate } from "react-router-dom";
import BannerAdd from "./banner-add-form";
import SelecatableStores from "../Store/SelectableStores";
import BannerImageUpload from "./upload-image";

const BannerCreate = () => {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [bannerId, setBannerId] = useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <div className="container-fluid">
      <div>
        <Stepper
          steps={[
            { title: "Create Banner" },
            { title: "Upload Image" },
            { title: "Assign Branches" },
          ]}
          activeStep={activeStep}
          activeColor={"#5096FF"}
          titleFontSize={18}
          size={38}
          defaultColor="#eee"
          activeTitleColor="#000"
          completeTitleColor="#5096FF"
          defaultTitleColor="#000"
          circleFontColor="#000"
          completeBarColor="#5096FF"
          lineMarginOffset={0}
        />
      </div>

      {activeStep === 0 && (
        <BannerAdd
          onSubmitClick={(id) => {
            setBannerId(id);
            handleNext();
          }}
        />
      )}

      {activeStep === 1 && (
        <BannerImageUpload
          type={"banner"}
          id={bannerId}
          onSubmitClick={() => {
            handleNext();
          }}
        />
      )}
      {activeStep === 2 && (
        <SelecatableStores
          type={"banner"}
          id={bannerId}
          onSubmitClick={() => {
            navigate(-1);
          }}
        />
      )}
    </div>
  );
};

export default BannerCreate;
