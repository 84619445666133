import { createSlice } from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
  name: "loader",
  initialState: {
    loading: false,
  },
  reducers: {
    changeStatus: (state, data) => {
      state.loading = data.payload;
    },
  },
});

export const { changeStatus } = loaderSlice.actions;

export const loadingStatus = (state) => state.rootReducer.loaderReducer.loading;

export default loaderSlice.reducer;
