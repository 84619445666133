import { combineReducers } from "redux";
import LoaderReducer from "../slice/loaderSlice";
import ToastReducer from "../slice/toastSlice";
import FormUpdateReducer from "../slice/formUpdateSlice";
import SelectedItemsReducer from "../slice/selectedItemsSlice";

const rootReducer = combineReducers({
  loaderReducer: LoaderReducer,
  toastReducer: ToastReducer,
  formUpdateReducer: FormUpdateReducer,
  selectedItemsReducer: SelectedItemsReducer,
});

export default rootReducer;
