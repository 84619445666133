import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { listAction } from "../../network/store/action/BannerResponseAction";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import { Flex } from "antd";
import GMCTPagination from "../../components/pagination";
import { useNavigate } from "react-router-dom";
import { bannerImageUplaodRequest } from "../../network/service/ImageUploadService";

import BannerItem from "./banner-item";

function Banner() {
  const bannerTypeList = [
    {
      displayName: "All",
      name: "",
    },
    {
      displayName: "Home",
      name: "HOME",
    },
    {
      displayName: "Medium",
      name: "MEDIUM",
    },
    {
      displayName: "Small",
      name: "SMALL",
    },
  ];

  const StoreList = [
    {
      id: "",
      name: "All",
    },
    {
      id: "1",
      name: "",
    },
    {
      id: "2",
      name: "",
    },
  ];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(5);
  const [pageOffset, setPageOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  //Filters
  const [filterForm, setFilterForm] = useState({
    bannerType: "",
    storeId: "",
  });

  // list of items
  const [resultList, setResultList] = useState([]);
  const [selectedForEdit, setSelectedForEdit] = useState({});
  const [showRequestForm, setShowRequestForm] = useState(false);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadData(pageLimit, pageOffset, filterForm.bannerType, filterForm.storeId);
  }, []);

  useEffect(() => {
    loadData(pageLimit, pageOffset, filterForm.bannerType, filterForm.storeId);
  }, [pageLimit, pageOffset, filterForm]);

  const loadData = async (limit, offset, type, storeId) => {
    await dispatch(
      listAction(
        { limit: limit, offset: offset, type: type, storeId: storeId },
        dispatch
      )
    ).then((reponse) => {
      if (reponse.result && reponse.result !== null) {
        setResultList(reponse.result);
        setTotalCount(Number(reponse.itemCount.totalFilterCount));
        setPageCount(reponse.itemCount.pageCount);
      } else {
        setResultList([]);
        setTotalCount(0);
        setPageCount(0);
      }
    });
  };

  const handleChanges = (e) => {
    resetFilter();
    setFilterForm({ ...filterForm, [e.target.name]: e.target.value });
  };

  const resetFilter = () => {
    setPageOffset(0);
    setTotalCount(0);
    setResultList([]);
    setPageCount(0);
    setActivePage(1);
  };

  const handlePageChange = (selectedPage) => {
    setActivePage(selectedPage);
    setPageOffset((selectedPage - 1) * pageLimit);
  };
  const handleLimitChange = (e) => {
    setPageLimit(e.target.value);
    setPageOffset(0);
    setActivePage(1);
  };

  const onCloseEvent = () => {
    setShowRequestForm(false);
    setResultList([]);
    setSelectedForEdit({});

    loadData();
  };

  return (
    <>
      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Banners</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              <button
                type="button"
                className="mr-auto btn btn-purple-bg"
                onClick={() => navigate("/addBanner")}
              >
                + Add Banner
              </button>
            </div>
          </div>
        </div>
        <div className="row m-3">
          <div className="col-md-4">
            <div className="form-group">
              <label>Search Banner</label>
              <input
                type="text"
                name="name"
                className="form-control"
                value={""}
                placeholder="Enter Product name"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label>Banner Type</label>
              <select
                name="bannerType"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={filterForm.bannerType}
              >
                <option value={""}>{"--Select Section--"}</option>
                {bannerTypeList &&
                  bannerTypeList.length > 0 &&
                  bannerTypeList.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.displayName}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label>Store</label>
              <select
                name="storeId"
                className="form-select"
                onChange={(e) => handleChanges(e)}
                value={filterForm.storeId}
              >
                <option value={""}>{"--Select Category--"}</option>
                {StoreList &&
                  StoreList.length > 0 &&
                  StoreList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-md-12 card-1 overflow-auto">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>

                  <th scope="col">Action</th>
                </tr>
              </thead>
              {resultList && resultList.length > 0 ? (
                <tbody>
                  {resultList.map((item, i) => {
                    return <BannerItem item={item} />;
                  })}
                </tbody>
              ) : (
                <p>No Data found</p>
              )}
            </table>

            <GMCTPagination
              pageCount={pageCount}
              activePage={activePage}
              totalCount={totalCount}
              onPageChange={(page) => handlePageChange(page)}
              onLimitChange={(e) => handleLimitChange(e)}
            />
          </div>
        </div>
      </main>
    </>
  );
}

export default Banner;
