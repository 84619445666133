import { useState } from "react";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { updateFormInfo } from "../../redux/slice/formUpdateSlice";
const UomItem = (props) => {
  const [item] = useState(props.item);
  const dispatch = useDispatch();

  const editItem = () => {
    dispatch(updateFormInfo({ displayForm: true, formData: { item } }));
  };

  return (
    <tr>
      <td className="align-middle">
        <p>{item.id}</p>
      </td>

      <td className="align-middle">
        <p>{item.name}</p>
      </td>

      <td className="align-middle">
        <p className="col-lg-12 d-flex justify-content-center">
          <span
            className="edit-icon-btn"
            onClick={() => {
              editItem();
            }}
          >
            <FaRegEdit />
          </span>
          <span className="delete-icon-btn">
            <FaTrash />
          </span>
        </p>
      </td>
    </tr>
  );
};

export default UomItem;
