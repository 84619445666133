import { createSlice } from "@reduxjs/toolkit";

export const toastSlice = createSlice({
  name: "toastMsg",
  initialState: {
    msgInfo: {
      display: false,
      message: "",
    },
  },
  reducers: {
    updateMessage: (state, data) => {
      state.msgInfo.display = data.payload.display;
      state.msgInfo.message = data.payload.message;
    },
  },
});

export const { updateMessage } = toastSlice.actions;

export const toastStatus = (state) => state.rootReducer.toastReducer.msgInfo;

export default toastSlice.reducer;
