import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Protected from "./pages/protected-route";
import Dashboard from "./pages/Home/Dashboard";
import Login from "./auth/login";
import Tags from "./pages/tags/tags";
import DailyReport from "./pages/reports/DalyReports";
import WeeklyReport from "./pages/reports/WeeklyReport";
import MonthlyReport from "./pages/reports/MonthlyReport";
import YearlyReport from "./pages/reports/YearlyReport";
import CustomReport from "./pages/reports/CustomReports";
import Banner from "./pages/banner/banner";
import Category from "./pages/category/category";
import SubCategory from "./pages/subcategory/subcategory";
import Brands from "./pages/brands/brands";
import Admin from "./pages/users/Admin";
import AppUsers from "./pages/users/AppUsers";
import StoreUsers from "./pages/users/StoreUsers";
import DeliveryBoys from "./pages/users/DeliveryBoys";
import Store from "./pages/Store/Store";
import ChangePassword from "./auth/ChangePassword";
import ProductRequest from "./pages/product-request/product-request";
import TaxSlab from "./pages/tax/tax-slab";
import HsnCodes from "./pages/hsn/hsn";
import Colors from "./pages/colors/colors";
import MaterialType from "./pages/material/MaterialType";
import Uom from "./pages/uom/uom";
import ProductUpdate from "./pages/products/product-update-form";
import ProductPriceAdd from "./pages/products/product-price-add-form";
import ProductPriceUpdate from "./pages/products/product-price-update-form";
import ProductTwo from "./pages/ProductTwo/products";
import MultiStepForm from "./pages/ProductTwo/product-create";
import BannerAdd from "./pages/banner/banner-add-form";
import Orders from "./pages/order/Orders";
import CreateOrder from "./pages/order/CreateOrder";
import ProductAdd from "./pages/products/product-add-form";
import BannerCreate from "./pages/banner/banner-create";

export default function Routers() {
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/login" />} />

      <Route
        path="/dashboard"
        element={<Protected>{<Dashboard />}</Protected>}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/banners" element={<Banner />} />
      <Route path="/categories" element={<Category />} />
      <Route path="/subcategories" element={<SubCategory />} />
      <Route path="/tags" element={<Tags />} />
      <Route path="/products" element={<ProductTwo />} />
      <Route path="/prequest" element={<ProductRequest />} />
      <Route path="/corder" element={<CreateOrder />} />

      <Route path="/porders" element={<Orders />} />
      <Route path="/dreports" element={<DailyReport />} />
      <Route path="/wreports" element={<WeeklyReport />} />
      <Route path="/mreports" element={<MonthlyReport />} />
      <Route path="/yreports" element={<YearlyReport />} />
      <Route path="/creports" element={<CustomReport />} />
      <Route path="/brands" element={<Brands />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/appusers" element={<AppUsers />} />
      <Route path="/storeusers" element={<StoreUsers />} />
      <Route path="/deliveryboys" element={<DeliveryBoys />} />
      <Route path="/stores" element={<Store />} />
      <Route path="/changepassword" element={<ChangePassword />} />
      <Route path="/taxSlabs" element={<TaxSlab />} />
      <Route path="/hsn" element={<HsnCodes />} />
      <Route path="/colors" element={<Colors />} />
      <Route path="/mtypes" element={<MaterialType />} />
      <Route path="/cuprdct" element={<MultiStepForm />} />
      <Route path="/udprdct" element={<ProductUpdate />} />
      <Route path="/udprice" element={<ProductPriceUpdate />} />
      <Route path="/uom" element={<Uom />} />
      <Route path="/crprice" element={<ProductPriceAdd />} />
      <Route path="/addBanner" element={<BannerCreate />} />
    </Routes>
  );
}
