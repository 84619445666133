import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { priceCreateUpdateAction } from "../../network/store/action/ProductPriceAction";
import { displayForm, updateFormInfo } from "../../redux/slice/formUpdateSlice";
import { listAction as uomListAction } from "../../network/store/action/UomResponseAction";

const PriceEntryForm = (props) => {
  // TO get Callbacks
  const dispatch = useDispatch();

  const [uomList, setUomList] = useState([]);

  // Page Form
  const [dataForm, setDataForm] = useState({
    id: "",
    productId: props.id,
    price: "",
    offer: "",
    maxQantity: "",
    sku: "",
    measure: "",
    qty: "",
    pPrice: "",
    sQuantity: "",
    sIndicator: "",
    userMaxQnty: "",
    status: "YES",
  });

  useEffect(() => {
    console.log("props.id", props.id);
    getUomList();
  }, []);

  const getUomList = async () => {
    await dispatch(uomListAction({}, dispatch)).then((response) => {
      if (response.result) {
        setUomList(response.result);
      }
    });
  };
  const handleInputChanges = (e) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmtClick = async () => {
    console.log("SDebug > ", dataForm);

    //   if (dataForm.id !== "" && dataForm.storeId !== "") {
    let requestBody = dataForm;
    await dispatch(priceCreateUpdateAction(requestBody)).then((response) => {
      console.log(response.status);
      console.log(response.message);
      if (response.status == true) {
        // showAlert(response.message);
        if (props.onSubmitClick) props.onSubmitClick();
      } else {
      }
    });
    //   }
  };

  return (
    <>
      <div className="d-flex" style={{ paddingLeft: "15%" }}>
        <div className="col-lg-8">
          <div className="container">
            <div className="row mt-5 mb-5 card-1" style={{ padding: "30px" }}>
              <h5 className="text-center">Item Price</h5>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group m-0">
                    <label>Quantity</label>
                    <input
                      type="text"
                      name="qty"
                      className="form-control"
                      value={dataForm.qty}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group m-0">
                    <label>Measure</label>
                    <select
                      name="measure"
                      className="form-select"
                      onChange={(e) => handleInputChanges(e)}
                      value={dataForm.measure}
                    >
                      <option value={""}>{"--Select UOM--"}</option>
                      {uomList &&
                        uomList.length > 0 &&
                        uomList.map((item, index) => {
                          return (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group m-0">
                    <label>Price</label>
                    <input
                      type="number"
                      name="price"
                      className="form-control"
                      value={dataForm.price}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group m-0">
                    <label>Offer%</label>
                    <input
                      type="number"
                      name="offer"
                      className="form-control"
                      value={dataForm.offer}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group m-0">
                    <label>Purchase Price</label>
                    <input
                      type="number"
                      name="pPrice"
                      className="form-control"
                      value={dataForm.pPrice}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group m-0">
                    <label>Stock Quantity</label>
                    <input
                      type="number"
                      name="sQuantity"
                      className="form-control"
                      value={dataForm.sQuantity}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group m-0">
                    <label>Stock Indicator</label>
                    <input
                      type="number"
                      name="sIndicator"
                      className="form-control"
                      value={dataForm.sIndicator}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group m-0">
                    <label>User Max Quantity</label>
                    <input
                      type="number"
                      name="userMaxQnty"
                      className="form-control"
                      value={dataForm.userMaxQnty}
                      onChange={(e) => handleInputChanges(e)}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                >
                  <button
                    style={{ display: "flex", justifyContent: "center" }}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => onSubmtClick()}
                  >
                    Submit
                  </button>

                  <button
                    className="btn btn-white-bg"
                    onClick={() => {
                      dispatch(displayForm({ displayForm: false }));
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PriceEntryForm;
