import {
  BANNER_API_PATH,
  PRODUCT_API_PATH,
  SECTION_API_PATH,
  SUB_CATGORY_API_PATH,
} from "../config/apiPaths";
import { getRequest } from "./config/GetRequest";
import { postRequest } from "./config/ImageUploadRequest";

export const productImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${PRODUCT_API_PATH.PRODUCT_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const categoryImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${SECTION_API_PATH.PRODUCT_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const subCategoryImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${SUB_CATGORY_API_PATH.PRODUCT_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};

export const bannerImageUplaodRequest = (data) => async () => {
  try {
    let result = await postRequest(
      `${BANNER_API_PATH.BANNER_IMAGE}?id=${data.id}`,
      data.fileData,
      true
    );
    return result;
  } catch (error) {
    // TODO Handle Error
    return error;
  }
};
