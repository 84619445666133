import { createSlice } from "@reduxjs/toolkit";

export const selectedItemsSlice = createSlice({
  name: "selectedItems",
  initialState: {
    items: [],
  },
  reducers: {
    onChangeData: (state, data) => {
      //  state.items = data.payload;
      state.items.push(data.payload);
    },
    onChangeDelete: (state, data) => {
      console.log("onChangeDelete", data.payload);
      //  state.items = data.payload;
      state.items = state.items.filter(function (item) {
        return item !== data.payload;
      });
    },
  },
});

export const { onChangeData, onChangeDelete } = selectedItemsSlice.actions;

export const selectedItemsStatus = (state) =>
  state.rootReducer.selectedItemsReducer.items;

export default selectedItemsSlice.reducer;
