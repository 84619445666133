import React, { useRef, useState } from "react";
import noImage from "../../assets/no_image.jpg";
import { bannerImageUplaodRequest } from "../../network/service/ImageUploadService";
import { useDispatch } from "react-redux";
import { updateMessage } from "../../redux/slice/toastSlice";
import AppToast from "../../components/appToast";
const BannerImageUpload = (props) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [fileToUpload, setFileToUpload] = useState();
  const hiddenFileInput = useRef(null);

  const handleImageInputChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null && fileUploaded != undefined) {
      setFile(URL.createObjectURL(fileUploaded));
      setFileToUpload(fileUploaded);
    }
  };
  const onImageClick = () => {
    hiddenFileInput.current.click();
  };
  const onSubmtClick = () => {
    if (file) {
      uploadImage();
    } else {
      alert("Select Image.");
      // dispatch(
      //   updateMessage({
      //     display: true,
      //     message: "Select Image.",
      //   })
      // );
    }
  };

  const uploadImage = async () => {
    const form_data = new FormData();
    form_data.append("file", fileToUpload);
    let requestBody = {
      id: props.id,
      fileData: form_data,
    };
    await dispatch(bannerImageUplaodRequest(requestBody)).then((response) => {
      if (props.onSubmitClick) props.onSubmitClick();
    });
  };

  return (
    <>
      <AppToast />
      <main className="container-fluid dashboard">
        <div className="row justify-content-around tble">
          <div className="container col-md-8  card-1">
            <div>
              <div style={{ marginLeft: "20px", marginTop: "10px" }}>
                <h3 className="dashboard-title">Select Image</h3>
              </div>
            </div>
            <div className="row m-3 d-flex">
              <div
                style={{ textAlign: "center" }}
                onClick={() => onImageClick()}
              >
                <input
                  type="file"
                  onChange={(e) => {
                    handleImageInputChange(e);
                  }}
                  ref={hiddenFileInput}
                  style={{ display: "none" }} // Make the file input element invisible
                />
                {file ? (
                  <>
                    <img
                      src={file}
                      alt={"Banner Image"}
                      className="img-thumbnail"
                      width="500px"
                      height="500px"
                    />
                  </>
                ) : (
                  <img
                    src={noImage}
                    alt={"Banner Image"}
                    className="img-thumbnail"
                    width="500px"
                  />
                )}
              </div>

              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{ display: "flex", justifyContent: "center" }}
                  type="button"
                  className="btn btn-purple-bg"
                  onClick={() => onSubmtClick()}
                >
                  Upload
                </button>
                {/* <button
                  className="btn btn-white-bg"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default BannerImageUpload;
