import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";
import { storage } from "../../firebase";
import { v4 } from "uuid";
import FirebaseDataService from "../../services/firebase.service";
import { listAction as categoryListAction } from "../../network/store/action/SectionResponseAction";
import { listAction } from "../../network/store/action/BrandResponseAction";
import GMCTLoader from "../../components/loader";
import EasyCrop from "../../components/easy-crop";

const ProductRequestEditForm = (props) => {
  const dispatch = useDispatch();
  const [imageUpload, setImageUpload] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [showImageCroper, setShowImageCroper] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [checkForm, setCheckForm] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [dataForm, setDataForm] = useState({
    name: props.itemData && props.itemData.name ? props.itemData.name : "",
    description:
      props.itemData && props.itemData.description
        ? props.itemData.description
        : "",
    brand: props.itemData && props.itemData.brand ? props.itemData.brand : "",
    itemType:
      props.itemData && props.itemData.itemType ? props.itemData.itemType : "",
    sectionId:
      props.itemData && props.itemData.sectionId
        ? props.itemData.sectionId
        : "",
    categoryId:
      props.itemData && props.itemData.categoryId
        ? props.itemData.categoryId
        : "",
    image_path:
      props.itemData && props.itemData.image_path
        ? props.itemData.image_path
        : "",
    store_id: "2",
    status: "pending",
  });
  const [sectionName, setSectionName] = useState("");
  const [categoryName, setCategoryName] = useState("");

  const uploadFile = () => {
    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/${v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        let itemObj = {
          name: dataForm.name,
          description: dataForm.description,
          brand: dataForm.brand,
          itemType: dataForm.itemType,
          sectionId: dataForm.sectionId,
          categoryId: dataForm.categoryId,
          sectionName: sectionName,
          categoryName: categoryName,
          image_path: url,
          store_id: dataForm.store_id,
          storeName: "Anaparthi",
          status: "pending",
          record_id: "",
        };
        console.log(itemObj);
        let pid =
          props.itemData && props.itemData.key ? props.itemData.key : v4();
        FirebaseDataService.create(pid, itemObj)
          .then(() => {
            console.log("Created new item successfully!");
            setShowLoader(false);
            if (props.onClose) props.onClose();
            // this.setState({
            //   submitted: true,
            // });
          })
          .catch((e) => {
            setShowLoader(false);
            console.log(e);
          });
      });
    });
  };

  useEffect(() => {
    getSections();
    getBrands();
    if (props.itemData && props.itemData && props.itemData.sectionId) {
      setImageUrl(props.itemData.image_path);
    }
  }, []);

  const getSections = async () => {
    await dispatch(categoryListAction({ sectionId: null }, dispatch)).then(
      (response) => {
        console.log("sectionList", response.result);
        if (response.result) {
          setSectionList(response.result);
          if (props.itemData && props.itemData && props.itemData.sectionId) {
            const sectionIdex = response.result.findIndex(
              (obj) => obj.id === props.itemData.sectionId
            );
            setCategoryList(response.result[sectionIdex].categories);
            setSectionName(
              response.result.find((sec) => sec.id === props.itemData.sectionId)
                .name
            );
            setCategoryName(
              response.result[sectionIdex].categories.find(
                (cat) => cat.id === props.itemData.categoryId
              ).name
            );
          }
        }
      }
    );
  };

  const getBrands = async () => {
    await dispatch(listAction(dispatch)).then((reponse) => {
      if (reponse.result) {
        setBrandList(reponse.result);
      }
    });
  };

  const handleInputChanges = (e) => {
    if (e.target.name === "sectionId") {
      // getSections(e.target.value);
      const sectionIdex = sectionList.findIndex(
        (obj) => obj.id === e.target.value
      );
      setCategoryList(sectionList[sectionIdex].categories);
    }
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmtClick = async () => {
    if (checkValidation()) {
      setShowLoader(true);
      if (props.itemData && !isImageChanged) {
        let itemObj = {
          name: dataForm.name,
          description: dataForm.description,
          brand: dataForm.brand,
          itemType: dataForm.itemType,
          sectionId: dataForm.sectionId,
          sectionName: sectionName,
          categoryName: categoryName,
          categoryId: dataForm.categoryId,
          image_path: props.itemData.image_path,
          store_id: dataForm.store_id,
          storeName: "Anaparthi",
          status: "pending",
          record_id: "",
        };
        console.log(itemObj);
        let pid = props.itemData.key;
        FirebaseDataService.create(pid, itemObj)
          .then(() => {
            console.log("Created new item successfully!");
            setShowLoader(false);
            if (props.onClose) props.onClose();
            // this.setState({
            //   submitted: true,
            // });
          })
          .catch((e) => {
            setShowLoader(false);
            console.log(e);
          });
      } else {
        uploadFile();
      }
    }
  };

  const checkValidation = () => {
    setCheckForm(true);
    var isFormValid = true;
    if (
      !dataForm.name ||
      !dataForm.description ||
      !dataForm.categoryId ||
      !dataForm.sectionId ||
      !dataForm.itemType ||
      !dataForm.brand ||
      !imageUrl
    ) {
      isFormValid = false;
    }
    return isFormValid;
  };

  const getCroppedImage = (file) => {
    setImageUpload(file);
    let previewUrl = file ? URL.createObjectURL(file) : "";
    setImageUrl(previewUrl);
    setShowImageCroper(false);
  };

  return (
    <>
      {showImageCroper && imageUpload !== "" && (
        <div
          className="cropper"
          style={{
            width: "100%",
            height: "100%",
            background: "#fff",
            position: "absolute",
            left: "0",
          }}
        >
          <EasyCrop
            image={URL.createObjectURL(imageUpload)}
            onCrop={(file) => {
              getCroppedImage(file);
            }}
            onCropClose={() => {
              setShowImageCroper(false);
              setImageUpload("");
            }}
          />
        </div>
      )}
      {!showImageCroper && (
        <div>
          <div className="container">
            <div className="row m-2">
              <div className="col-md-6 card-1 p-3">
                <h3 className="text-center">Request Product</h3>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group m-0">
                      <label>Item Name</label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={dataForm.name}
                        onChange={(e) => handleInputChanges(e)}
                      />
                      {checkForm && !dataForm.name && (
                        <span className="validation-error">
                          Can not be blank
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group m-0">
                      <label>Item description</label>
                      <textarea
                        name="description"
                        className="form-control"
                        value={dataForm.description}
                        onChange={(e) => handleInputChanges(e)}
                        rows="3"
                      ></textarea>
                      {checkForm && !dataForm.description && (
                        <span className="validation-error">
                          Can not be blank
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group m-0">
                      <label>Sections</label>
                      <select
                        name="sectionId"
                        className="form-select"
                        onChange={(e) => {
                          handleInputChanges(e);
                          setSectionName(e.target.selectedOptions[0].text);
                        }}
                        value={dataForm.sectionId}
                      >
                        <option value={""}>{"--Select Section--"}</option>
                        {sectionList &&
                          sectionList.length > 0 &&
                          sectionList.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                      {checkForm && !dataForm.sectionId && (
                        <span className="validation-error">
                          Can not be blank
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group m-0">
                      <label>Categories</label>
                      <select
                        name="categoryId"
                        className="form-select"
                        onChange={(e) => {
                          handleInputChanges(e);
                          setCategoryName(e.target.selectedOptions[0].text);
                        }}
                        value={dataForm.categoryId}
                      >
                        <option value={""}>{"--Select Section--"}</option>
                        {categoryList &&
                          categoryList.length > 0 &&
                          categoryList.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                      {checkForm && !dataForm.categoryId && (
                        <span className="validation-error">
                          Can not be blank
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group m-0">
                      <label>Brand</label>
                      <select
                        name="brand"
                        className="form-select"
                        onChange={(e) => handleInputChanges(e)}
                        value={dataForm.brand}
                      >
                        <option value={""}>{"--Select Section--"}</option>
                        {brandList &&
                          brandList.length > 0 &&
                          brandList.map((item, index) => {
                            return (
                              <option key={index} value={item.name}>
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                      {checkForm && !dataForm.brand && (
                        <span className="validation-error">
                          Can not be blank
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group m-0">
                      <label>Item Type</label>
                      <select
                        name="itemType"
                        className="form-select"
                        onChange={(e) => handleInputChanges(e)}
                        value={dataForm.itemType}
                      >
                        <option value={""}>{"--Select Section--"}</option>
                        <option value={"Veg"}>{"Veg"}</option>
                        <option value={"Non-veg"}>{"Non-veg"}</option>
                      </select>
                      {checkForm && !dataForm.itemType && (
                        <span className="validation-error">
                          Can not be blank
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mt-3">
                      <label>Image</label>
                      <input
                        type="file"
                        onChange={(event) => {
                          setImageUpload(event.target.files[0]);
                          // let previewUrl = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : "";
                          // setImageUrl(previewUrl);
                          setIsImageChanged(true);
                          setShowImageCroper(true);
                        }}
                        class="form-control-file"
                      />
                      {checkForm && !imageUrl && (
                        <span className="validation-error">Select Image</span>
                      )}
                      <img
                        src={imageUrl}
                        rounded
                        width={250}
                        className="img-thumbnail mt-3"
                      />
                    </div>
                  </div>
                  <br />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  >
                    {showLoader ? (
                      <GMCTLoader />
                    ) : (
                      <>
                        <button
                          style={{ display: "flex", justifyContent: "center" }}
                          type="button"
                          className="btn btn-primary"
                          onClick={() => onSubmtClick()}
                        >
                          Submit
                        </button>
                        <button
                          className="btn btn-white-bg"
                          onClick={() => {
                            if (props.onClose) props.onClose();
                          }}
                        >
                          Cancel
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductRequestEditForm;
