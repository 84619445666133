import { useRef, useState } from "react";
import { bannerImageUplaodRequest } from "../../network/service/ImageUploadService";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import noImage from "../../assets/no_image.jpg";

function BannerItem(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [item] = useState(props.item);
  const [imageUpload, setImageUpload] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [showImageCroper, setShowImageCroper] = useState(false);
  const [file, setFile] = useState();
  const hiddenFileInput = useRef(null);
  const handleImageInputChange = (item, event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded != null && fileUploaded != undefined) {
      setFile(URL.createObjectURL(fileUploaded));
      uploadImage(item, fileUploaded);
    }
  };

  const editItem = (item) => {};
  const onImageClick = () => {
    hiddenFileInput.current.click();
  };
  const uploadImage = async (item, file) => {
    const form_data = new FormData();
    form_data.append("file", file);
    let requestBody = {
      id: item.id,
      fileData: form_data,
    };
    await dispatch(bannerImageUplaodRequest(requestBody)).then(
      (response) => {}
    );
  };
  return (
    <tr>
      {/* <th scope="row">{od.id}</th> */}
      <td className="align-middle">{item.id}</td>
      <td className="align-middle">
        <div className="" onClick={() => onImageClick()}>
          <input
            type="file"
            onChange={(e) => {
              handleImageInputChange(item, e);
            }}
            ref={hiddenFileInput}
            style={{ display: "none" }} // Make the file input element invisible
          />
          {file ? (
            <>
              <img
                src={file}
                alt={item.name}
                className="img-thumbnail"
                width="120px"
                height="80px"
              />
            </>
          ) : item.image_path !== "" ? (
            <img
              src={item.image_path}
              alt={item.name}
              className="img-thumbnail"
              width="80px"
              height="80px"
            />
          ) : (
            <img
              src={noImage}
              alt={item.name}
              className="img-thumbnail"
              width="120px"
              height="80px"
            />
          )}
        </div>
      </td>
      <td className="align-middle">
        <p>{item.name}</p>
      </td>
      <td className="align-middle">
        <p className="col-lg-12 d-flex justify-content-center">
          {/* <span
            className="edit-icon-btn"
            onClick={() => {
              editItem(item);
            }}
          >
            <FaRegEdit />
          </span>{" "} */}
          <span className="delete-icon-btn">
            <FaTrash />
          </span>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={(event) => {
              setImageUpload(event.target.files[0]);
              // let previewUrl = event.target.files[0] ? URL.createObjectURL(event.target.files[0]) : "";
              // setImageUrl(previewUrl);
              setIsImageChanged(true);
              setShowImageCroper(true);
            }}
            class="form-control-file"
          />
        </p>
      </td>
    </tr>
  );
}

export default BannerItem;
