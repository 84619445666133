import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listAction, createUpdateAction } from "../../network/store/action/ProductResponseAction";
import { listAction as categoryListAction } from "../../network/store/action/SectionResponseAction";
import noImage from '../../assets/no_image.jpg';
import GMCTPagination from '../../components/pagination';
import ProductRequestEditForm from "./pr-edit-form";
import FirebaseDataService from "../../services/firebase.service";
// import { Table } from "react-bootstrap";
import { FaEye, FaTrash, FaEdit, FaPlus } from "react-icons/fa";
import './product-request.css';

function ProductRequest() {
  // to get api data

  const dispatch = useDispatch();

  const [sectionList, setSectionList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  // list of items
  const [resultList, setResultList] = useState([]);
  const [showPrEditForm, setShowPrEditForm] = useState(false);
  // progress Dialog
  const [isLoading, setIsLoading] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [approveItemData, setApproveItemData] = useState({});
  const [itemEditData, setItemEditData] = useState({});

  //Pagination
  const [totalCount, setTotalCount] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageOffset, setPageOffset] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const [productAddForm, setproductAddForm] = useState({
    "sectionId": "",
    "categoryId": "",
    "storeId": 0
  });

  useEffect(() => {
    getSections();
    getItemList();
    // getProducts(pageLimit, pageOffset, productAddForm.sectionId, productAddForm.categoryId, productAddForm.storeId);
  }, []);

  const snapshotToArray = snapshot => Object.entries(snapshot).map(e => Object.assign(e[1], { key: e[0] }));
  const getItemList = async () => {
    FirebaseDataService.getAll().then((snapshot) => {
      if (snapshot.exists()) {
        // setResultList(snapshotToArray(snapshot.val()));
        let itemArr = snapshotToArray(snapshot.val());
        if (itemArr && itemArr.length > 0) {
          setResultList(itemArr);
          // setShowProductDeleteModel(false);
        }
        console.log('items', snapshotToArray(snapshot.val()));
      } else {
        console.log("No data available");
        setResultList([]);
        // setShowProductDeleteModel(false);
      }
    }).catch((e) => {
      console.log(e);
    });
  }

  useEffect(() => {
    // getProducts(pageLimit, pageOffset, productAddForm.sectionId, productAddForm.categoryId, productAddForm.storeId);
  }, [pageLimit, pageOffset, productAddForm]);

  const getSections = async () => {
    await dispatch(categoryListAction({}, dispatch)).then((response) => {
      console.log('sectionList', response.result);
      if (response.result) {
        setSectionList(response.result);
      }
    });

  }

  const getProducts = async (limit, offset, sectionId, categoryId, storeId) => {
    await dispatch(listAction({ limit: limit, offset: offset, sectionId: sectionId, categoryId: categoryId, storeId: storeId }, dispatch)).then((reponse) => {
      if (reponse.result) {
        setResultList(reponse.result);
        setTotalCount(Number(reponse.itemCount.totalFilterCount));
        setPageCount(reponse.itemCount.pageCount)
      } else {
        resetFilter();
      }
    });
  };

  const resetFilter = () => {
    setPageOffset(0);
    setTotalCount(0);
    setResultList([]);
    setPageCount(0)
    setActivePage(1)
  }

  const handlePageChange = (selectedPage) => {
    setActivePage(selectedPage);
    setPageOffset((selectedPage - 1) * pageLimit);
  }

  const handleLimitChange = (e) => {
    setPageLimit(e.target.value);
    setPageOffset(0);
    setActivePage(1);
  }

  const handleChanges = (e) => {
    console.log('SDebug > handleChanges', e);
    if (e.target.name === 'sectionId') {
      const sectionIdex = sectionList.findIndex(obj => obj.id === e.target.value);
      setCategoryList(sectionList[sectionIdex].categories)
      setproductAddForm({ ...productAddForm, "categoryId": "", "sectionId": e.target.value })
    } else {
      setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value })
    }
    //  getProducts(pageLimit,pageOffset, e.target.name === 'sectionId'?e.target.value : '', e.target.name === 'categoryId'?e.target.value : '');
    //  setproductAddForm({ ...productAddForm, [e.target.name]: e.target.value })
  }

  const getStockLable = (stock) => {
    if (stock < 1) {
      return <span className="badge bg-danger">{stock}</span>
    } else if (stock < 10) {
      return <span className="badge bg-warning">{stock}</span>
    } else if (stock >= 10) {
      return <span className="badge bg-success">{stock}</span>
    }
  }

  const editProduct = async (data) => {
    setItemEditData(data);
    setShowPrEditForm(true)
  }


  const approveItem = async () => {
    let itemObj = {
      name: approveItemData.name,
      description: approveItemData.description,
      brand: approveItemData.brand,
      itemType: approveItemData.itemType,
      sectionId: approveItemData.sectionId,
      categoryId: approveItemData.categoryId,
      image_path: approveItemData.image_path,
      status: "YES"
    }
    console.log(itemObj)
    await dispatch(createUpdateAction(itemObj, dispatch)).then((response) => {
      console.log('sectionList', response.result);
      if (response.result) {
        let fbItemeObj = {
          name: approveItemData.name,
          description: approveItemData.description,
          brand: approveItemData.brand,
          itemType: approveItemData.itemType,
          sectionId: approveItemData.sectionId,
          sectionName: approveItemData.sectionName,
          categoryName: approveItemData.categoryName,
          categoryId: approveItemData.categoryId,
          image_path: approveItemData.image_path,
          store_id: approveItemData.store_id,
          storeName: approveItemData.storeName,
          status: "approved",
          record_id: response.result.id
        }
        let pid = approveItemData.key;
        FirebaseDataService.create(pid, fbItemeObj).then(() => {
          console.log("Created new item successfully!");
          getItemList();
        }).catch((e) => {
          // setApproveModal(false);
        });
        setApproveModal(false);
        // setSectionList(response.result);
      }
    });
  }

  return (
    <>

      {approveModal && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <div className="row align-item-center justify-content-center m-3">
            <div className="col-md-3">
              <div className="card-1 mt-5 p-3">
                <p>Are you sure do u want to add item?</p>
                <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>

                  <button
                    style={{ display: "flex", justifyContent: "center" }}
                    type="button"
                    className="btn btn-primary"
                    onClick={() => approveItem()}
                  >
                    YES
                  </button>
                  <button
                    className="btn btn-white-bg"
                    onClick={() => {
                      setApproveModal(false);
                      // setShowProductDeleteModel(false);
                    }}
                  >
                    NO
                  </button>



                </div>
              </div>

            </div>
          </div>
        </div>
      )}
      {showPrEditForm && (
        <div
          style={{
            position: "fixed",
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "100%",
            height: "100%",
            zIndex: "999",
            overflow: "scroll",
          }}
        >
          <div className="d-flex align-item-center justify-content-center">
            <div className="col-md-8"  style={{marginBottom: "4rem"}}>
              <ProductRequestEditForm onClose={() => { setShowPrEditForm(false); getItemList(); }} itemData={itemEditData} />
            </div>
          </div>
        </div>
      )}

      <main className="container-fluid dashboard">
        <div className="row m-3">
          <div className="row justify-content-around tble">
            <div className="col-6">
              <h3 className="dashboard-title">Product Requests</h3>
            </div>
            <div className="mr-auto col-6 text-end">
              <button
                type="button"
                className="mr-auto btn btn-primary"
              // onClick={() => setShowRequestForm(true)}
              >
                + Add Product
              </button>
            </div>
          </div>
        </div>
        <div className="row m-3">

          <div className='col-md-3'>
            <div className="form-group">
              <label>Search Product</label>
              <input type="text" name="name" className="form-control" value={''} placeholder="Enter Product name" />
            </div>
          </div>
          <div className='col-md-3'>
            <div className="form-group">
              <label>Section</label>
              <select name="sectionId" className="form-select" onChange={(e) => handleChanges(e)} value={productAddForm.sectionId}>
                <option value={''}>{'--Select Section--'}</option>
                {sectionList && sectionList.length > 0 && sectionList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>{item.name}</option>
                  )
                })}
              </select>
            </div>
          </div>

          <div className='col-md-3'>
            <div className="form-group">
              <label>Category</label>
              <select name="categoryId" className="form-select" onChange={(e) => handleChanges(e)} value={productAddForm.categoryId}>
                <option value={''}>{'--Select Category--'}</option>
                {categoryList && categoryList.length > 0 && categoryList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>{item.name}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='col-md-3'>
            <div className="form-group">
              <label>Select Store Prices</label>
              <select name="storeId" className="form-select" onChange={(e) => handleChanges(e)} value={productAddForm.storeId}>
                <option value={''}>{'--Select Category--'}</option>
                return(
                <option value={0}>{'None'}</option>
                <option value={1}>{'Kavali'}</option>
                <option value={2}>{'Anaparthi'}</option>
                )
              </select>
            </div>
          </div>

          <div className="col-md-12 card-1 h-scroll">
            <table className="table table-hover align-middle">
              <thead className="thead-dark">
                <tr>
                  {/* <th scope="col">Id</th> */}
                  <th scope="col">Image</th>
                  <th scope="col">Item Info</th>
                  {/* <th scope="col">category</th> */}
                  <th scope="col">Store</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              {resultList && resultList.length > 0 ? (
                <tbody>
                  {resultList.map((el, i) => {
                    return (
                      <tr key={i}>
                        {/* <th scope="row">{od.id}</th> */}
                        {/* <td className="align-middle">
                          <p>{el.item.id}</p>
                        </td> */}
                        <td className="align-middle">
                          {el.image_path !== "" ? (<img
                            src={el.image_path}
                            alt={el.name}
                            className="img-thumbnail"
                            width="50px"
                            height="50px"
                          />) : (<img
                            src={noImage}
                            alt={el.name}
                            className="img-thumbnail"
                            width="50px"
                            height="50px"
                          />)}
                        </td>
                        <td className="align-middle" style={{ maxWidth: "200px" }}>
                          <p>{el.name}</p>
                          <p style={{ fontSize: ".8rem", color: "rgb(150 150 150" }}>{el.description}</p>
                          <p style={{ fontSize: ".7rem", color: "black", fontWeight: "500" }}>{el.sectionName + '>' + el.categoryName}</p>
                          {/* {productAddForm.storeId != 0 && el.prices && el.prices.length > 0 && el.prices.map((price, i) => {
                            return (
                              <>
                                <div className="price-box position-relative">
                                  <span className="float-edit-btn" onClick={() => { setShowPriceEditForm(true) }}><FaEdit /></span>
                                  <span>{price.quantity} {getStockLable(price.stock_quantity)}</span><br />
                                  <span>{'SP: '}{price.offer_price !== '' ? (<><b>{'₹' + price.offer_price}</b> <span style={{ textDecoration: 'line-through', color: 'grey' }}>{'₹' + price.price}</span></>) : (<b>{'₹' + price.price}</b>)} </span><br />
                                  <span>{'PP: ₹' + price.purchase_price}</span><br />
                                </div>
                              </>

                            )
                          })}
                          {productAddForm.storeId != 0 && 
                               (<div className="add-btn-box position-relative">
                               <span className="price-add-btn" onClick={() => { setShowPriceEditForm(true) }}><FaPlus /></span>
                             </div>)} */}

                        </td>
                        {/* <td className="align-middle">
                          
                        </td> */}
                        <td className="align-middle">
                          <p>{el.storeName}</p>
                        </td>
                        {/* <td className="align-middle">
                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" checked={el.item.status === "YES" ? true : false} />
                          </div>
                        </td> */}
                        <td className="align-middle">
                          {el.status === "approved" ? (
                            <>
                              <span
                                style={{ fontSize: ".7rem", color: "green", fontWeight: "bold" }}
                              >APPROVED</span>
                              <p>Record id: {el.record_id}</p>
                            </>

                          ) : (
                            <>
                              <span
                                className="edit-icon-btn"
                                onClick={() => {
                                  editProduct(el);
                                }}
                                style={{ fontSize: ".7rem" }}
                              >
                                <FaEdit />
                              </span>
                              <span
                                className="edit-icon-btn"
                                onClick={() => {
                                  setApproveItemData(el);
                                  setApproveModal(true);
                                }}
                                style={{ fontSize: ".7rem" }}
                              >
                                Approve
                              </span>
                              <span
                                className="delete-icon-btn"
                                style={{ fontSize: ".7rem" }}
                              >
                                Reject
                              </span>
                            </>
                          )}


                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                <p>No Data found</p>
              )}
            </table>

            {/* <GMCTPagination pageCount={pageCount} activePage={activePage} totalCount={totalCount} onPageChange={(page) => handlePageChange(page)} onLimitChange={(e) => handleLimitChange(e)} /> */}

          </div>
        </div>
      </main>
    </>
  );
}
export default ProductRequest;
