import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { listAction } from "../../network/store/action/UserResponseAction";
// import { Table } from "react-bootstrap";
import {
  FaRegEdit,
  FaTrash
} from "react-icons/fa";

function AppUsers() {
 // to get api data

 const dispatch = useDispatch();

 // list of items
 const [resultList, setResultList] = useState([]);
 const [showRequestForm, setShowRequestForm] = useState(false);
 // progress Dialog
 const [isLoading, setIsLoading] = useState(false);

 useEffect(() => {
   loadData();
 }, []);

 const loadData = async () => {
   await dispatch(listAction(dispatch)).then((reponse) => {
     if (reponse.result) {
       setResultList(reponse.result);
     }
   });
 };

 return (
   <>
{showRequestForm && (
           <div
       style={{
           position: "fixed",
           backgroundColor: "rgba(0,0,0,0.5)",
           width: "100%",
           height: "100%",
           zIndex: "999",
           overflow:"scroll"
       }}
       >
           <div className="d-flex align-item-center justify-content-center">
               <div className="col-md-8">
               {/* <BannerForm onClose={() => {setShowRequestForm(false)}} sectionList={[]}/> */}
               </div>
           </div>
       </div>
       )}

     <main className="container-fluid dashboard">
       <div className="row m-3">
         <div className="col-md-12">
           <h3 className="dashboard-title">APP USERS</h3>
         </div>
       </div>
       <div className="row m-3">
         <div className="col-md-12 card-1">
           <table className="table table-hover align-middle">
             <thead className="thead-dark">
               <tr>
                 <th scope="col">Id</th>
                 {/* <th scope="col">Image</th> */}
                 <th scope="col">Name</th>
                 <th scope="col">Type</th>
                 <th scope="col">Status</th>
                 <th scope="col">Action</th>
               </tr>
             </thead>
             {resultList && resultList.length > 0 ? (
               <tbody>
                 {resultList.map((item, i) => {
                   return (
                     <tr key={i}>
                       {/* <th scope="row">{od.id}</th> */}
                       <td className="align-middle">
                         <p>{item.id}</p>
                       </td>
                       {/* <td className="align-middle"><img src={item.image_path} alt={item.name} width="100px" height="80px" /></td> */}
                       <td className="align-middle">
                         <p>{item.name}</p>
                       </td>
                       <td className="align-middle">
                         <p>{item.type}</p>
                       </td>
                       <td className="align-middle">
                         <p>{item.status}</p>
                       </td>
                       <td className="align-middle">
                        <p><span className="edit-icon-btn" onClick={() => {setShowRequestForm(true)}}><FaRegEdit /></span> <span  className="delete-icon-btn"><FaTrash /></span></p>
                       </td>
                     </tr>
                   );
                 })}
               </tbody>
             ) : (
               <p>No Data found</p>
             )}
           </table>

           <nav aria-label="Page navigation example">
 <ul class="pagination">
   <li class="page-item">
     <a class="page-link" href="#" aria-label="Previous">
       <span aria-hidden="true">&laquo;</span>
       <span class="sr-only">Previous</span>
     </a>
   </li>
   <li class="page-item"><a class="page-link" href="#">1</a></li>
   <li class="page-item"><a class="page-link" href="#">2</a></li>
   <li class="page-item"><a class="page-link" href="#">3</a></li>
   <li class="page-item">
     <a class="page-link" href="#" aria-label="Next">
       <span aria-hidden="true">&raquo;</span>
       <span class="sr-only">Next</span>
     </a>
   </li>
 </ul>
</nav>

         </div>
       </div>
     </main>
   </>
 );
}

export default AppUsers